body, .kt-datatable > .kt-datatable__table > .kt-datatable__head .kt-datatable__row > .kt-datatable__cell .kt-checkbox.kt-checkbox--solid > span, .kt-datatable > .kt-datatable__table > .kt-datatable__body .kt-datatable__row > .kt-datatable__cell .kt-checkbox.kt-checkbox--solid > span, .kt-datatable > .kt-datatable__table > .kt-datatable__foot .kt-datatable__row > .kt-datatable__cell .kt-checkbox.kt-checkbox--solid > span {
  background: #cdced3
}

.kt-datatable > .kt-datatable__table > .kt-datatable__head .kt-datatable__row > .kt-datatable__cell .kt-checkbox.kt-checkbox--solid > input:checked ~ span, .kt-datatable > .kt-datatable__table > .kt-datatable__body .kt-datatable__row > .kt-datatable__cell .kt-checkbox.kt-checkbox--solid > input:checked ~ span, .kt-datatable > .kt-datatable__table > .kt-datatable__foot .kt-datatable__row > .kt-datatable__cell .kt-checkbox.kt-checkbox--solid > input:checked ~ span {
  background: #cdced3
}

.kt-checkbox.kt-checkbox--solid > span:after {
  border: solid #fff;
}

.kt-datatable.kt-datatable--default > .kt-datatable__table > .kt-datatable__body .kt-datatable__row > .kt-datatable__cell > span {
  color: #292929;
}

input[type=checkbox] {
  box-shadow: none;
  outline: 1px solid #1e5180;
}

.btn-icon i:before {
  color: #1e5180;
  font-weight: 600;
}

.time-slot-container {
  margin-bottom: 10px;
  border-width: 1px;
  border-color: #5d91c2;
  border-radius: 5px;
  border-style: solid;
  padding-left: 10px;
  padding-top: 20px;
  padding-bottom: 10px;
  color: #4a6e8f;
}

.date-slot-title {
  padding-top: 10px;
  color: #888;
}

.date-slot {
  margin-bottom: 20px;
}

.time-slot-container:hover,
.time-slot-container-active
 {
  color: white;
  background: #1e5180;
}

.banner-admin {
  padding: 10px;
  width: 100%;
  background-color: #F78129;
  position: fixed;
}
